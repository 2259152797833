import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/news"

const News = () => {
  const title = "Aktualności"

  return (
    <Layout
      seo={{
        title: title,
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} withBorder />
      <Content />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpCategory {
      nodes {
        name
        slug
        wpParent {
          node {
            slug
          }
        }
      }
    }
  }
`

export default News
